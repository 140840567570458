.section_padding{
    padding: 4rem 4rem;
}
.footer{
    background-color: rgb(67, 36, 150);
}
.sb_footer {
    display: flex;
    flex-direction: column;
}
.sb_footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.sb_footer-links_div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    color: white;
}
a{
    color: rgb(175,175,179);
    text-decoration: none;
}
.socialmedia{
    display: flex;
    flex-direction: row;
}
.socialmedia:hover{
    cursor: pointer;
    justify-content: space-between;
}
.sb_footer-links-div p {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
    color: white;
    
}
.discrepionFooter {
    overflow: hidden;
    width: 600px;
}
.sb_footer-links-div h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
    color: white;
}

.sb_footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0,2rem;
}
.sb_footer-below-links{
    display: flex;
    flex-direction: row;
}

hr{
    color: white !important;
    width: 100%;
}
.sb_footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color: rgb(255,255,255);
    font-weight: 600;
}
@media screen and (max-width: 850px) {
    .sb_footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}
@media screen and (max-width: 550px) {
    .socialmedia {
        display: flex;
        flex-direction: column;
    }
    .sb_footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    .sb_footer-links div {
        margin: 1rem 0;
    }
    .sb_footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }
    .sb_footer-below{
        flex-direction: column;
        justify-content: left;
    }
    .sb_footer-below-links {
       flex-direction: column; 
    }
    .sb_footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}
@media screen and (max-width: 400px) {
    .sb_footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
}