.services-submenu {
    width: 20rem;
    position: absolute;
    top: 67px;
    list-style: none;
    text-align: start;
    overflow: hidden;
    z-index: 11;
    
}

.services-submenu li {
    background: rgb(67, 36, 150);
    cursor: pointer;
}

.services-submenu li a:hover {
    background: rgb(64, 68, 252);
    color: white;
}

.services-submenu.clicked {
    display: none;
}

.submenu-item {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: white;
    padding: 16px;
}