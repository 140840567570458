*{
    font-family: 'Roboto', 'Arial Narrow', sans-serif;
}
body {
    overflow-x: hidden;
}
.logo_and-aboutUs{
    display: flex;
    justify-content: space-between; 
   
}
.home-aboutUs {
    overflow: hidden;
    margin-left: 15rem;
    
}
.home-aboutUs h1{
    color: rgb(25, 115, 194);
}
.logo-aside-aboutUs{
    margin-left: 15rem;
}
.Containe_WhatWeDo{
    margin: 0;
    max-width: 1300px;
    width: 100%;
}
.Containe_WhatWeDo h1{
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 40px;
}
.Card_listes{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.card{
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(20.33% - 20px);
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    transition: .3s;
}
section {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.countup{
    background-color: rgb(67, 36, 150);
    padding: 80px;
    color: white;
}
.countup h1{
    color: rgb(108, 108, 247);
    margin: 1rem;
    display: flex;
    font-size: 60px;
    display: inline;
}
.about_us{
    margin: 20px;
}
.about_us h2 {
    text-align: center;
    font-size: 36px;
}
.about_us p{
    font-size: 18px;
    text-align: justify;
    margin: 0 auto;
        /* Allow text to wrap within the specified width */
    width: 30%;
}
.countup_list img{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.countup_list{
    align-items: center;
    padding-left: 3rem;
    display: flex;
    justify-content: space-around;
}
.countup_img{
    align-items: center;
    padding-left: 5rem;
    display: flex;
    justify-content: space-around;
}
.Why_choose_us{
    margin: 5rem;
    padding-left: 7rem;
    flex: auto;
    justify-content: space-between;

}
.Why_choose_us h1 {
    text-align: center;
    font-weight: bold;
}
.why_choose_img{
    display: flex;
    padding-right: 10rem;
    margin-left: 3rem;
    flex: auto;
    
    justify-content: space-between;
    
}
.why_choose_img h3{
    color: rgb(255, 255, 255);
}
.why_img1{
    
        font-weight: bold;
        
        top: 50%;
        left: 50%;
        font-size: 1.5rem;
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    box-sizing: border-box;
    width: 20vh;
    height: 20vh;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    
    background-image: url('../asset/reliability.jpg');
    
}

.why_img2{
font-weight: bold;

    top: 50%;
    left: 50%;
font-size: 1.5rem;
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    width: 20vh;
    height: 20vh;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-image: url('../asset/adva.jpg');
}


.why_img3 {
font-weight: bold;
    
    top: 50%;
    left: 50%;
font-size: 1.5rem;
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    width: 20vh;
    height: 20vh;
    background-size: cover;
    background-position: center;
    justify-content: center;
    
    
    background-image: url('../asset/sm.jpg');
}
.disableBlur {
filter: blur(0);
        
        
        
}

.why_img4 {
    font-weight: bold;

    top: 50%;
    left: 50%;
font-size: 1.5rem;
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    width: 20vh;
    height: 20vh;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-image: url('../asset/whpvr.jpg');

}

.why_img4 img {
    padding-left: 2rem;
}
.why_img5 {
    font-weight: bold;

    top: 50%;
    left: 50%;
font-size: 1.5rem;
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    width: 20vh;
    height: 20vh;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-image: url('../asset/costE.jpg');

}

.why_img5 img {
    padding-left: 2rem;
}
.Our_client h1{
   text-align: center;
}
.Our_client img
{
    margin: 5rem;
    margin-left: 6rem;
}
.Our_client img:hover{
    cursor: pointer;
    transform: scale(1.1);
        /* Adjust the scale factor as needed */
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.card2{
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(20.33% - 20px);
        width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
flex-wrap: wrap;
padding: 20px;
margin-bottom: 20px;
transition: .3s;
}
.Card_listes2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-items: center;
    /* justify-content: space-between;
     */
}
@media screen and (max-width: 760px) {
    .home-aboutUs {
            display: initial;
        }
}
@media screen and (max-width: 550px) {
    .countup h1 {
        margin: 3rem;
        display: flex;
    }
        .Card_listes2{
            display: flex;
            flex-direction: column;
            width: 100rem;
            padding-left: 60px;
        }
        .Why_choose_us{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
                .logo_and-aboutUs {
                    display: flex;
                
                    flex-direction: column;
        
                }
                .logo-aside-aboutUs{
                    padding-top: 0px;
                    margin: 0px;

                }
.countup_list{
    display: flex;
    flex-direction: column;
}
.countup_list img{
    display: flex;
    flex-direction: column;
}
    .home-aboutUs{
    display: initial;
        padding: 0;
        margin: 0;
    }
   
    .Card_listes {
            
        flex-direction: column;
        justify-content: space-between;
    }
    .card {
        flex: 0 0 calc(33.33% - 20px);
        max-width: fit-content;
        width: 100%;
        background: #fff;
        padding: 20px;
        margin-bottom: 20px;
                    
    }
        .Why_choose_us {
            margin: 5rem;
            flex-direction: column;
            flex: auto;
            justify-content: space-between;
    
        }
                .why_choose_img {
                    max-width: fit-content;
                    display: flex;
                    flex-direction: column;
                    padding-right: 1rem;
                    margin-left: 3rem;
                    flex: auto;
                    justify-content: space-between;
                }
        
        
}
