  p{
    font-family: "Arial", sans-serif;
  }
  .forAllService {
      display: flex;
      align-items: center;
  }

  .about_us {
      flex: 1;
      margin-right: 20px;
      font-size: 30px;
      /* Adjust as needed */
    font-family: "Arial", sans-serif;
    text-align: left;
    font-size: 20px;
  }

  .sideImage {
      max-width: 100%;
      padding-bottom: 5px;
      /* Ensures the image doesn't exceed its container */
  }
  @media screen and (max-width: 550px) {
    .forAllService{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .about_us{
        padding: 0px;
        margin: 0px;
        
    }
  }